<template>
    <div class="order">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>筛选字段</span>
                    <el-select
                        v-model="field"
                        size="small"
                        style="width:100px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="客户名称" value="1"></el-option>
                        <el-option label="联系人" value="2"></el-option>
                        <el-option label="联系手机" value="3"></el-option>
                        <el-option label="备注" value="4"></el-option>
                    </el-select>

                    <el-input
                        size="small"
                        style="width:150px;margin-left:16px"
                        placeholder="请输入内容"
                        v-model="fieldVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>创建时间</span>
                    <el-radio-group v-model="createTime" size="small">
                        <el-radio-button
                            label="3天内"
                            @click.native.prevent="createitem('3天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="10天内"
                            @click.native.prevent="createitem('10天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="30天内"
                            @click.native.prevent="createitem('30天内')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="createDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:240px;margin-left:19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="margin-right:16px; border-color: #2370EB;background:#2370EB"
                        size="small"
                        @click="getTableData"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="margin:0;background:none;border-color:#D9D9D9;color:#333333"
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                    <el-button
                        v-show="fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right:29px;color:#2162CB"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div class="search-two" v-show="fold">
                <span>订单状态</span>
                <el-radio-group v-model="status" size="small">
                    <el-radio-button
                        label="未交付"
                        @click.native.prevent="createStatus('未交付')"
                    ></el-radio-button>
                    <el-radio-button
                        label="部分交付"
                        @click.native.prevent="createStatus('部分交付')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已交付"
                        @click.native.prevent="createStatus('已交付')"
                    ></el-radio-button>
                </el-radio-group>
                <span>购买产品</span>
                <el-select
                    v-model="productCategory"
                    size="small"
                    style="width:160px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData"
                >
                    <el-option label="腾讯企业邮箱" value="1"></el-option>
                    <el-option label="阿里企业邮箱" value="2"></el-option>
                    <el-option label="云产品" value="3"></el-option>
                    <el-option label="网易企业邮箱" value="4"></el-option>
                    <el-option label="网站" value="5"></el-option>
                    <el-option label="投屏盒子" value="6"></el-option>
                    <el-option label="微盘" value="7"></el-option>
                    <el-option label="主机" value="8"></el-option>
                    <el-option label="服务器" value="9"></el-option>
                    <el-option label="会话存档" value="10"></el-option>
                    <el-option label="企业微信" value="11"></el-option>
                </el-select>
                <span>成员</span>
                <el-select
                    v-model="adminIdVal"
                    size="small"
                    style="width:180px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData()"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="table-title">
            <div style="display:flex">
                <p>共计金额 {{ statistics.totalAmount }}（{{ statistics.orderCount }}单）</p>
            </div>
            <span> </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="15"> </el-table-column>
            <el-table-column label="客户名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button type="text" style="color:#2370EB" @click="onDetails(scope.row)">{{
                        scope.row.companyName
                    }}</el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="购买产品"
                width="150"
                show-overflow-tooltip
                :formatter="productCategoryVal"
            >
            </el-table-column>
            <el-table-column
                label="版本"
                show-overflow-tooltip
                v-if="productCategory == 1 || productCategory == 2"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.productCategory == 1">
                        {{
                            scope.row.version == 1
                                ? '老版'
                                : scope.row.version == 2
                                ? '新版'
                                : '- -'
                        }}
                    </span>
                    <span v-if="scope.row.productCategory == 2">
                        {{
                            scope.row.version == 1
                                ? '标准版'
                                : scope.row.version == 2
                                ? '尊享版'
                                : scope.row.version == 3
                                ? '集团版'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="订单类型"
                show-overflow-tooltip
                v-if="
                    productCategory == 1 ||
                        productCategory == 2 ||
                        productCategory == 3 ||
                        productCategory == 10
                "
            >
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.productCategory == 1 ||
                                scope.row.productCategory == 2 ||
                                scope.row.productCategory == 10
                        "
                    >
                        {{
                            scope.row.orderType == 1
                                ? '新增'
                                : scope.row.orderType == 2
                                ? '续费'
                                : scope.row.orderType == 3
                                ? '扩用户'
                                : '- -'
                        }}
                    </span>

                    <span v-if="scope.row.productCategory == 3">
                        {{
                            scope.row.orderType == 1
                                ? '腾讯云'
                                : scope.row.orderType == 2
                                ? '阿里云'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="用户数"
                prop="orderNumber"
                show-overflow-tooltip
                v-if="productCategory == 1 || productCategory == 2"
            >
            </el-table-column>
            <el-table-column
                label="下单年限"
                prop="totalTerm"
                show-overflow-tooltip
                v-if="productCategory == 1 || productCategory == 2"
            >
            </el-table-column>
            <el-table-column
                label="购买年限"
                prop="orderTerm"
                show-overflow-tooltip
                v-if="productCategory == 10"
            >
            </el-table-column>
            <el-table-column
                label="VIP账号金额"
                prop="vipAmount"
                min-width="100"
                show-overflow-tooltip
                v-if="productCategory == 10"
            >
            </el-table-column>
            <el-table-column
                label="系统金额"
                prop="systemAmount"
                show-overflow-tooltip
                v-if="productCategory == 10"
            >
            </el-table-column
            ><el-table-column label="原价" prop="formerAmount" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="订单金额" prop="amount" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="试用状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.tencentTrialStatus == 1
                            ? '未试用'
                            : scope.row.tencentTrialStatus == 2
                            ? '申请试用'
                            : scope.row.tencentTrialStatus == 3
                            ? '试用中'
                            : scope.row.tencentTrialStatus == 4
                            ? '试用到期'
                            : scope.row.tencentTrialStatus == 5
                            ? '试用结束'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '未交付'
                            : scope.row.status == 2
                            ? '部分交付'
                            : scope.row.status == 3
                            ? '已交付'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="商务" prop="adminName" show-overflow-tooltip> </el-table-column>

            <el-table-column label="创建人" prop="createAdminName" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" fixed="right" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <el-button
                        v-show="btnP.orderdeliver"
                        @click="onDeliver(scope.row)"
                        type="text"
                        style="color:#2370EB"
                        >交付</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 交付 -->
        <el-dialog :visible.sync="drawerDeliver" width="560px" :before-close="handleClose">
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    交付
                </div>
            </template>
            <Deliver @close="handleClose" ref="deliver" />
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    订单详情
                </div>
            </template>
            <Details @close="handleClose" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import { orderList, statisticsAll } from '@/api/user/business/order';
import Details from '../../order/details.vue';
import Deliver from './deliver.vue';
import { roleList } from '@/api/customer/customer';
export default {
    components: {
        Deliver,
        Details,
    },
    data() {
        return {
            statistics: {},
            fold: true,
            field: '',
            fieldVal: '',
            adminIdVal: '',
            createTime: '',
            createDate: [],
            status: '',
            productCategory: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerDeliver: false,
            drawer: false,
            direction: 'rtl',
            options: [],
            btnP: {},
        };
    },
    mounted() {
        this.createDate = this.$searchTime.thisMonth();
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);

            this.getUserList();

            this.getTableData();
        },
        getUserList() {
            let data = {
                param: {
                    roleTypeList: [4, 8, 9, 10, 17],
                },
            };
            roleList(data).then((res) => {
                this.options = res.data;
                this.getTableData();
            });
        },
        getStatistics(data) {
            statisticsAll(data).then((res) => {
                if (res.data) {
                    this.statistics = res.data;
                } else {
                    this.statistics = {};
                }
            });
        },
        getTableData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.field != '' && this.fieldVal == '') {
                return this.$message.error('请输入查询字段内容');
            } else if (this.field == '' && this.fieldVal != '') {
                return this.$message.error('请选择查询字段');
            } else if (this.field == 1) {
                data.param.companyName = this.fieldVal;
            } else if (this.field == 2) {
                data.param.contactName = this.fieldVal;
            } else if (this.field == 3) {
                data.param.contactPhone = this.fieldVal;
            } else if (this.field == 4) {
                data.param.remark = this.fieldVal;
            }
            if (this.createTime != '') {
                let date = new Date();
                this.createDate = [];
                data.param.endCreateDate = this.$searchTime.getNextDate(date, 0);
                if (this.createTime == '3天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(date, 3);
                } else if (this.createTime == '10天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(date, 10);
                } else if (this.createTime == '30天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(date, 30);
                }
            }
            if (this.status != '') {
                if (this.status == '未交付') {
                    data.param.status = 1;
                } else if (this.status == '部分交付') {
                    data.param.status = 2;
                } else if (this.status == '已交付') {
                    data.param.status = 3;
                }
            }
            if (this.createDate && this.createDate.length > 0) {
                data.param.startCreateDate = this.createDate[0];
                data.param.endCreateDate = this.createDate[1];
            }
            if (this.productCategory != '') {
                data.param.productCategory = this.productCategory;
            }
            if (this.adminIdVal != '') {
                data.param.adminId = this.adminIdVal;
            }
            orderList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.getStatistics(data);
            });
        },
        // 创建时间点击
        createitem(e) {
            e === this.createTime ? (this.createTime = '') : (this.createTime = e);
            this.getTableData();
        },
        // 状态点击
        createStatus(e) {
            e === this.status ? (this.status = '') : (this.status = e);
            this.getTableData();
        },
        // 时间输入框变化
        expireTime() {
            if (this.createDate && this.createDate.length > 0) {
                this.createTime = '';
            }
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 购买产品
        productCategoryVal(row) {
            return this.$tableDataHandle.productCategory(row.productCategory);
        },

        // 交付
        onDeliver(row) {
            this.drawerDeliver = true;
            let that = this;
            let arr = [row];
            setTimeout(() => {
                that.$refs.deliver.getData(arr);
            }, 50);
        },
        // 详情
        onDetails(row) {
            this.drawer = true;
            setTimeout(() => {
                this.$refs.details.getData(row);
            }, 50);
        },
        handleClose() {
            this.drawerDeliver = false;
            this.drawer = false;
            this.getTableData();
        },
        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.createTime = '';
            this.createDate = [];
            this.status = '';
            this.getTableData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #2370eb;
            color: #2370eb;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            line-height: 10px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>

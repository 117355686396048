<template>
    <div class="deliver">
        <div class="changePerson-box">
            <p>
                <el-button
                    type="primary"
                    style="padding:0;color:#2370EB;background:none;margin-left:-22px"
                    ><i class="el-icon-check"></i
                ></el-button>
                已选择{{ data.length }}条订单
            </p>
            <div class="shu"></div>
            <p>
                <span></span>
                订单状态变更
                <el-select
                    v-model="status"
                    size="small"
                    style="margin-left:0;width:270px"
                    clearable
                    placeholder="请选择"
                >
                    <el-option label="未交付" value="1"></el-option>
                    <el-option label="部分交付" value="2"></el-option>
                    <el-option label="已交付" value="3"></el-option>
                </el-select>
            </p>
            <el-form
                style="flex:1;margin-top:40px;margin-left: 100px"
                label-position="left"
                label-width="80px"
            >
                <el-form-item label="服务期限" required>
                    <el-date-picker
                        v-model="serviceTime"
                        size="small"
                        type="daterange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:270px"
                        clearable
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="应收金额">
                    <span>{{ amount }}</span>
                </el-form-item>
                <el-form-item label="实收金额" required>
                    <el-input
                        style="width:270px"
                        v-model="actualAmount"
                        size="small"
                        placeholder="请输入实收金额"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="corpid" v-show="productCategory == 1">
                    <el-input
                        v-model="corpId"
                        style="width:270px;"
                        size="small"
                        placeholder="请输入corpid"
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { orderUpdate } from '@/api/user/business/order';
export default {
    data() {
        return {
            data: [],
            amount: '',
            status: '',
            serviceTime: [],
            actualAmount: '',
            corpId: '',
            productCategory: '',
        };
    },
    methods: {
        getData(data) {
            // console.log(data);
            this.data = data;
            this.productCategory = data[0].productCategory;
            this.status = '';
            this.serviceTime = [];
            this.actualAmount = '';
            this.corpId = '';
            if (data[0].corpId) {
                this.corpId = data[0].corpId;
            }
            this.amount = data[0].amount;
            this.actualAmount = data[0].actualAmount;
            this.status = String(data[0].status);
            if (data[0].startServiceTime && data[0].endServiceTime) {
                this.serviceTime = [
                    data[0].startServiceTime,
                    data[0].endServiceTime,
                ];
            }

            this.actualAmount = data[0].actualAmount;
        },
        onSubmit() {
            if (!this.status) {
                this.$message.error('请选择订单状态变更');
            } else if (!this.serviceTime || this.serviceTime.length <= 0) {
                return this.$message.error('请选择服务期限');
            } else if (!this.actualAmount) {
                return this.$message.error('请输入实收金额');
            } else {
                let data = {
                    param: {
                        id: this.data[0].id,
                        status: this.status,
                        actualAmount: this.actualAmount,
                        corpId: this.corpId,
                    },
                };
                data.param.startServiceTime = this.serviceTime[0];
                data.param.endServiceTime = this.serviceTime[1];
                orderUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    }
                    this.onClose();
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deliver {
    width: 100%;
    .changePerson-box {
        padding: 38px 0 156px 0;
        p {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-left: 94px;
        }
        .shu {
            height: 32px;
            border-left: 1px solid #eeeeee;
            margin: 10px 0;
            margin-left: 79px;
        }
        p:nth-child(3) span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #2370eb;
            border-radius: 50%;
            margin-left: -20px;
            margin-right: 3px;
        }
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>

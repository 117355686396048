import http from '@/utils/http.js'

// 所有订单列表
export function orderList(data) {
    return http({
        url: "/jasoboss/order/list",
        method: "post",
        data
    })
}
//交付
export function orderUpdate(data) {
    return http({
        url: "/jasoboss/order/update",
        method: "post",
        data
    })
}
//所有订单列表上方统计
export function statisticsAll(data) {
    return http({
        url: "/jasoboss/order/list/statistics",
        method: "post",
        data
    })
}